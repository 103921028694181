import en from "./en.js"
import ch from "./ch.js"
import id from "./id.js"
import hk from "./hk.js"


export default {
    toggle: function() {
        var languagetype = localStorage.getItem('language');
        if (!languagetype) {
            // localStorage.setItem('language', 'zh-cn')
            // languagetype = 'zh-cn'

            localStorage.setItem('language', 'zh-hk')
            languagetype = 'zh-hk'
        }
        console.log(languagetype)
        if (languagetype == 'en-us') {
            return en
        } else if (languagetype == 'zh-cn'){
            return ch
        } else if (languagetype == 'id-id'){
            return id
        } else if (languagetype == 'zh-hk'){
            return hk
        }else{
            // localStorage.setItem('language', 'zh-cn')
            // return ch

            localStorage.setItem('language', 'zh-hk')
            return hk
        }
    }
}